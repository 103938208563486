<template lang='pug'>
article.delivery
  .delivery__cont.cont
    .delivery__content
      h1.delivery__caption.caption Доставка
      p.delivery__text.text-big
        | Осуществляем доставку по России. При оформлении заказа выберите удобный способ доставки или воспользуйтесь самовывозом
      p.delivery__list-title.text-big
        | Стоимость доставки по РФ:
      ul.delivery__list
        li.delivery__item.text-big
          | От 390 ₽ - доставка транспортной компанией СДЭК
        li.delivery__item.text-big
          | От 350 ₽ - доставка Почтой России
      p.delivery__list-title.text-big
        | Точная стоимость доставки будет расчитана после сборки заказа
      p.delivery__list-title.text-big
        | Бесплатная доставка по России при заказе от {{minPriceForFreeDelivery}} ₽
      h2.delivery__title.title Самовывоз
      p.delivery__text.text-big
        | Адрес пункта самовывоза: {{address}}
      p.delivery__text.text-big(v-html="worktime.weekdays")
      p.delivery__text.text-big
        | Выдаём только после оплаты и при наличии с собой подтверждающих документов
</template>

<script>
import { worktime, address, minPriceForFreeDelivery } from '../../assets/scripts/variables'

export default {
  name: 'TheDelivery',
  data () {
    return {
      worktime,
      address,
      minPriceForFreeDelivery
    }
  }
}
</script>

<style lang='sass'>
@import 'src/assets/styles/components/delivery'
</style>
