<template lang="pug">
head-meta(
  :title-page="'Доставка шоколада'",
  description="Стоимость и способы доставки шоколада по России. Самовывоз, Почта России или доставка транспортной компанией" )
the-delivery
contacts-line
</template>

<script>
import TheDelivery from '../components/delivery/TheDelivery'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'DeliveryPage',
  components: { ContactsLine, HeadMeta, TheDelivery }
}
</script>
